const DATA = {
  header: {
    slogan: "We bring together leading<br>market experts.",
    menu: [
      {
        name: "Events",
        url: "events",
      },
      {
        name: "Insights",
        url: "posts",
      },
      {
        name: "Reading room",
        url: "reading-room/",
      },
      // {
      //   name: "Horizon Panel",
      //   url: "#speakers",
      // },
      {
        name: "About",
        url: "#about",
      },
    ],
  },
  breadcrumbs: {
    home: "Home",
    events: "Events",
  },
  signUpButtonText: "Sign up to event",
  languages: {
    english: "English",
    spanish: "Spanish",
  },
  place: "The Venue",
  horizonPanel: "The Horizon Panel",
  aboutEvent: {
    title: "About the Event",
    timetable: "Timetable",
  },
  aboutPlatform: {
    important:
      "The EXANTE Horizon programme provides a global platform for financial market discussions and networking opportunities.",
    desc: "<p>We bring together experienced market analysts and ask them what is on the horizon for investors and traders. We host offline live streamed events in global financial hubs and burgeoning financial centres.</p><p>Bringing you the most relevant information and analysis, the Horizon programme helps you to see the bigger picture as you create your future financial horizon. View All Horizon Events Explore Horizon Insights</p>",
    viewEventsLink: "View All Horizon Events",
    exploreInsightsLink: "Explore Horizon Insights",
  },
  months: {
    january: "January",
    february: "February",
    march: "March",
    april: "April",
    may: "May",
    june: "June",
    july: "July",
    august: "August",
    september: "September",
    october: "October",
    november: "November",
    december: "December",
  },
  form: {
    title: "Join us",
    note: "Please note that seating is limited.",
    subtitle: "",
    thankyouText: "",
    textAfter:
      "Want to attend in person? Register <a href='/poland2023vip/'>here</a>!", // TODO: remake link for remove from text
    nameReq: "Name is required",
    emailValid: "E-mail must be valid",
    emailReq: "E-mail is required",
    someWrong: "Something went wrong..",
    thanks: "Thank you for registering!",
    back: "Back to form",
    name: "Name",
    surname: "Surname",
    company: "Company",
    phone: "Phone",
    email: "Email",
    marketingCom:
      "I would like to receive marketing communication about products, services and promotions offered by EXT Ltd. via email, sms, push notifications and by phone. I am aware that I can opt out at any time.",
    sighnup: "Register now",
    thanks: "Thank you for registering!",
    thankyouText:
      "Please keep an eye on your mailbox, as we’ll send you the live stream link in due course.",
    cancelled:
      "Thank you for your interest in our Horizon Poland event. Unfortunately, due to circumstances beyond our control, the event has been cancelled. If you have registered, we will inform you when we have a new date for this event. If you are yet to register, but would like to know about future Horizon events across the globe, please email <a href='mailto:horizon@exante.eu'>horizon@exante.eu</a>",
  },
  formOffline: {
    title: "Register to attend",
    note: "EXANTE Horizon events are free of charge. Please note that seating is limited.",
    subtitle: "",
    thankyouText: "",
    textAfter:
      "Can’t attend in person? Register <a href='/poland2023/'>here</a> to watch the webinar!", // TODO: remake link for remove from text
    nameReq: "Name is required",
    emailValid: "E-mail must be valid",
    emailReq: "E-mail is required",
    someWrong: "Something went wrong..",
    thanks: "Thank you for registering!",
    back: "Back to form",
    name: "Name",
    surname: "Surname",
    company: "Company",
    email: "Email",
    marketingCom:
      "I would like to receive marketing communication about products, services and promotions offered by EXT Ltd. via email, sms, push notifications and by phone. I am aware that I can opt out at any time.",
    sighnup: "Register now",
    thanks: "Thank you for registering!",
    thankyouText:
      "We look forward to meeting you. Please keep an eye on your mailbox as we’ll send you a follow-up email with further information regarding the venue and schedule, in due course.",
    cancelled:
      "Thank you for your interest in our Horizon Poland event. Unfortunately, due to circumstances beyond our control, the event has been cancelled. If you have registered, we will inform you when we have a new date for this event. If you are yet to register, but would like to know about future Horizon events across the globe, please email <a href='mailto:horizon@exante.eu'>horizon@exante.eu</a>",
  },
  footer: {
    copyright: "All rights reserved.",
    text: "EXANTE is a boutique investment company offering direct access to 50+ global financial markets using proprietary technology, backed by client-centric brokerage solutions. We are licensed by the FCA (UK), CySEC (Cyprus), MFSA (Malta) and SFC (Hong Kong) to provide trading services to professional and institutional clients in the UK, EU and Asia.",
    toTopText: "Back to top",
    openAccountText: "Open account",
    links: [
      {
        name: "HOME",
        url: "/",
      },
      {
        name: "Events",
        url: "/",
      },
      {
        name: "Insights",
        url: "/",
      },
      {
        name: "Reading room",
        url: "/",
      },
      {
        name: "About",
        url: "/",
      },
      {
        name: "Panel",
        url: "/",
      },
    ],
  },
}

export default DATA
