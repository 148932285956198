"use client"

import styles from "./Speakers.module.scss"
import Image from "next/image"
import { useState, useEffect, useRef } from "react"

const SpeakerContent = ({ item, isShort, bio, onclick }) => {
  const symbolsInOneRow = 36
  const rowsCount = 3

  return (
    <>
      <div
        className={`${styles.speakersItem__picture} ${
          item.label ? styles.speakersItem__picture_label : ""
        }`}
      >
        {item.imagex2 ? (
          <Image alt={item.name} src={item.imagex2} fill />
        ) : item.image ? (
          <Image alt={item.name} src={item.image} fill />
        ) : null}
        <span>{item.label}</span>
      </div>
      <h4
        className={styles.speakersItem__name}
        dangerouslySetInnerHTML={{ __html: item.name }}
      ></h4>
      <div
        className={styles.speakersItem__position}
        dangerouslySetInnerHTML={{ __html: item.position }}
      ></div>
      {!isShort && (
        <>
          <div
            // className={`${styles.speakersItem__text} ${
            //   isActive && !item.blured ? styles.speakersItem__text_active : ""
            // }`}
            className={styles.speakersItem__text}
            dangerouslySetInnerHTML={{ __html: bio }}
          ></div>
          {bio && bio.length / symbolsInOneRow > rowsCount && (
            <div
              // className={`${styles.speakersItem__more} ${
              //   isActive && !item.blured
              //     ? styles.speakersItem__more_turned
              //     : ""
              // }`}
              className={styles.speakersItem__more}
              onClick={onclick}
            ></div>
          )}
        </>
      )}
    </>
  )
}

export default function Speaker({ item, grid, isShort }) {
  const [isActive, setIsActive] = useState(false)
  const bio = item.bio || item.text

  const selfElem = useRef(null)

  useEffect(() => {
    const handleClick = (e) => {
      if (
        e.target !== selfElem.current &&
        e.target.parentNode.parentNode !== selfElem.current
      ) {
        setIsActive(false)
      }
    }

    if (isActive) {
      document.addEventListener("click", handleClick)
    }

    return () => {
      document.removeEventListener("click", handleClick)
    }
  }, [isActive])

  const toggleActive = () => {
    if (!isShort && !isActive && !item.blured) setIsActive(!isActive)
  }

  return (
    <div
      className={`${styles.speakersItem} ${
        item.blured ? styles.speakersItem_blured : ""
      }
        ${isShort ? styles.speakersItem_short : ""}
        ${isActive ? styles.speakersItem_active : ""}
      `}
      onClick={toggleActive}
      onMouseEnter={toggleActive}
      onMouseLeave={() => {
        setIsActive(false)
      }}
      ref={selfElem}
    >
      <div className={styles.speakersItem__wrap}>
        <SpeakerContent
          item={item}
          bio={bio}
          isShort={isShort}
          onClick={() => {
            setIsActive(!isActive)
          }}
        ></SpeakerContent>
      </div>
      {isActive && (
        <div
          className={`${styles.speakersItem__wrap} ${styles.speakersItem__wrap_fake}`}
        >
          <SpeakerContent
            item={item}
            bio={bio}
            isShort={isShort}
          ></SpeakerContent>
        </div>
      )}
      {/* <div className={styles.speakersItem__wrap}>
        <div
          className={`${styles.speakersItem__picture} ${
            item.label ? styles.speakersItem__picture_label : ""
          }`}
        >
          {item.imagex2 ? (
            <Image alt={item.name} src={item.imagex2} fill />
          ) : (
            <Image alt={item.name} src={item.image} fill />
          )}
          <span>{item.label}</span>
        </div>
        <h4
          className={styles.speakersItem__name}
          dangerouslySetInnerHTML={{ __html: item.name }}
        ></h4>
        <div
          className={styles.speakersItem__position}
          dangerouslySetInnerHTML={{ __html: item.position }}
        ></div>
        {!isShort && (
          <>
            <div
              className={`${styles.speakersItem__text} ${
                isActive && !item.blured ? styles.speakersItem__text_active : ""
              }`}
              dangerouslySetInnerHTML={{ __html: bio }}
            ></div>
            {bio && bio.length / symbolsInOneRow > rowsCount && (
              <div
                className={`${styles.speakersItem__more} ${
                  isActive && !item.blured
                    ? styles.speakersItem__more_turned
                    : ""
                }`}
                onClick={() => {
                  if (isActive) setIsActive(!isActive)
                }}
              ></div>
            )}
          </>
        )}
      </div> */}
    </div>
  )
}
