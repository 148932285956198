import Container from "../Container/Container"
import RegularButton from "../RegularButton/RegularButton"
import Modal from "../Modal/Modal"
import { useState, useEffect } from "react"
import styles from "./Intro.module.scss"

export default function Intro({ item, isEvent = false }) {
  const [isModalVisible, setIsModalVisible] = useState(false)

  function closeModal() {
    setIsModalVisible(false)
  }

  function openVideo() {
    setIsModalVisible(true)
  }

  function escClose(e) {
    if (isModalVisible) {
      if (e.code === "Escape") {
        closeModal()
      }
    }
  }

  if (typeof window !== "undefined") {
    document.addEventListener("keydown", escClose)
  }

  useEffect(() => {
    document.addEventListener("keydown", escClose)
  }, [])

  return (
    <section className={`${styles.intro} ${isEvent ? styles.intro_event : ""}`}>
      {item.background_normal && (
        <img
          className={`${styles.intro__image} ${styles.intro__image_desktop} ${
            !item.background_mobile_webp && !item.background_mobile
              ? styles.intro__image_onlydesktop
              : ""
          }`}
          src={item.background_normal}
          alt={item.title}
        />
      )}

      {(item.background_mobile_webp || item.background_mobile) && (
        <img
          className={`${styles.intro__image} ${styles.intro__image_mobile}`}
          src={
            item.background_mobile_webp
              ? item.background_mobile_webp
              : item.background_mobile
          }
          alt={item.title}
        />
      )}

      <Container className={styles.intro__container}>
        {isEvent && item.nav_title && (
          <div
            className={styles.intro__time}
            dangerouslySetInnerHTML={{ __html: item.nav_title }}
          ></div>
        )}
        <h1 dangerouslySetInnerHTML={{ __html: item.title }}></h1>
        {item.text && (
          <p
            className={isEvent ? styles.intro__place : ""}
            dangerouslySetInnerHTML={{ __html: item.text }}
          ></p>
        )}

        {item.buttons.length > 0 &&
          item.buttons.map((el, index) => {
            return (
              <RegularButton
                key={index}
                className={styles.intro__btn}
                text={el.title}
                link={el.href}
                newTabLink
              ></RegularButton>
            )
          })}
      </Container>
    </section>
  )
}
