import { getCookie, setCookie } from "./cookie"

export const UTMS = [
  "ep",
  "utm_referer",
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_term",
  "gclid",
  "dclid",
  "utm_id",
]

export function setUtmsToFormData(formData) {
  if (!URLSearchParams && !location) return
  const urlParams = new URLSearchParams(location.search)

  if (!Object.prototype.hasOwnProperty.call(formData, "set")) {
    for (const utmItem of UTMS) {
      const val = getCookie(utmItem)
      if (val) formData[utmItem] = val

      const paramVal = urlParams.get(utmItem)
      if (paramVal) formData[utmItem] = paramVal
    }
    return formData
  }

  for (const utmItem of UTMS) {
    const val = getCookie(utmItem)
    if (val) formData.set(utmItem, val)

    const paramVal = urlParams.get(utmItem)
    if (paramVal) formData.set(utmItem, paramVal)
  }

  return formData
}

export function setUtmsParamsToCookie() {
  const urlParams = new URLSearchParams(location.search)

  for (const utmItem of UTMS) {
    const paramVal = urlParams.get(utmItem)
    if (utmItem == "utm_term") {
      if (paramVal) setCookie("ep", paramVal, 365)
    }
    if (paramVal) setCookie(utmItem, paramVal, 365)
  }
}
