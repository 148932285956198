const DEFAULT_TIMEZONE = "CET"
const DEFAULT_TIMELANG = "en-GB"

export const getDate = (el) => {
  const date = new Date(el)
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()

  return `${String(day).padStart(2, "0")}.${String(month).padStart(
    2,
    "0"
  )}.${year}`
}

export const getDateWithTime = (el) => {
  const [datePart, timePart] = el.split("T")
  const [year, month, day] = datePart.split("-").map(Number)
  const [hour, minute] = timePart.split(":").map(Number)

  const formattedDate = `${String(day).padStart(2, "0")}.${String(
    month
  ).padStart(2, "0")}.${year}`

  const period = hour >= 12 ? "PM" : "AM"
  const formattedHour = hour % 12 || 12
  const formattedTime = `${formattedHour}:${String(minute).padStart(
    2,
    "0"
  )} ${period}`

  return `${formattedDate} ${formattedTime}`
}

export const getDateWithMonth = (
  el,
  lang = DEFAULT_TIMELANG,
  time_zone = DEFAULT_TIMEZONE
) => {
  if (lang === "en") lang = DEFAULT_TIMELANG
  const date = new Date(el)
  const optionsDate = {
    day: "numeric",
    month: "long",
    year: "numeric",
    timeZone: time_zone,
  }
  const formattedDate = date.toLocaleDateString(lang, optionsDate)
  return `${formattedDate}`
}

export const getDateWithMonthWithTime = (
  el,
  lang = DEFAULT_TIMELANG,
  time_zone = DEFAULT_TIMEZONE
) => {
  if (lang === "en") lang = DEFAULT_TIMELANG

  const [datePart, timePart] = el.split("T")

  const [hour, minute] = timePart.split(":").map(Number)

  const formattedDate = getDateWithMonth(el, lang, time_zone)

  let formattedTime
  if (lang === "pl") {
    // 24-hour format for Polish
    formattedTime = `${String(hour).padStart(2, "0")}:${String(minute).padStart(
      2,
      "0"
    )}`
  } else {
    const period = hour >= 12 ? "PM" : "AM"
    const formattedHour = hour % 12 || 12
    formattedTime = `${formattedHour}:${String(minute).padStart(
      2,
      "0"
    )} ${period}`
  }

  return `${formattedDate} ${formattedTime}`
}

export const getDateWithShortMonth = (
  el,
  lang = DEFAULT_TIMELANG,
  time_zone = DEFAULT_TIMEZONE
) => {
  if (lang === "en") lang = DEFAULT_TIMELANG
  const date = new Date(el)
  const options = { day: "numeric", month: "short", timeZone: time_zone }
  const formattedDate = date.toLocaleDateString(lang, options)

  const [day, month] = formattedDate.split(" ")

  return `${day} <span>${month}</span>`
}

export const getGMT = (timeZone) => {
  const date = new Date()

  const utcDate = new Date(date.toISOString())

  const formatter = new Intl.DateTimeFormat("en-US", {
    timeZone,
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
  })

  const localTimeString = formatter.format(date)

  const [localHours, localMinutes] = localTimeString.split(":").map(Number)

  const localTimeInMinutes = localHours * 60 + localMinutes

  // Calculate the UTC time in minutes
  const utcHours = utcDate.getUTCHours()
  const utcMinutes = utcDate.getUTCMinutes()
  const utcTimeInMinutes = utcHours * 60 + utcMinutes

  // Calculate the difference in hours
  const diffMinutes = localTimeInMinutes - utcTimeInMinutes
  const diffHours = Math.floor(diffMinutes / 60)

  // Construct the GMT offset
  const sign = diffHours >= 0 ? "+" : "-"
  const gmtOffset = `GMT${sign}${Math.abs(diffHours)}`

  return gmtOffset
}

export const sortDates = (items) => {
  return items.sort((a, b) => new Date(b.date_time) - new Date(a.date_time))
}

export const isPastEvent = (el, time_zone = DEFAULT_TIMEZONE) => {
  // Текущее время с учетом временной зоны
  const now = new Date().toUTCString(DEFAULT_TIMELANG, {
    timeZone: time_zone,
  })
  const currentDateTime = new Date(now)

  // Время события с учетом временной зоны
  const eventDateTime = new Date(
    new Date(el).toUTCString(DEFAULT_TIMELANG, { timeZone: time_zone })
  )

  return eventDateTime < currentDateTime
}
